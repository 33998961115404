<template>
  <div class="c-page-outro">
    <Page :i="7">
      <h1>You have been onboarded!</h1>
      <article class="article-text">
        <p>We have a comprehensive documentation & help section if you need help.</p>
        <p>If you still run into any snags, you can ask for support via the in-app chat widget at the bottom right corner of the screen.</p>
        <p>Enjoy!</p>
      </article>
      <ButtonCta :timer="2" @onClick="onClick">
        <span> Take me to my Library </span>
      </ButtonCta>
    </Page>
  </div>
</template>

<script>
import Page from './page';
import ButtonCta from './button-cta.vue';
export default {
  components: {
    Page,
    ButtonCta,
  },

  methods: {
    onClick: function () {
      this.$emit('onNext');
    },
  },
};
</script>

<style lang="scss"></style>
