<
<template>
	<div class="c-popup-block-options" v-if="target">
		<Popup :active="options.active" :target="target" @onClose="onClose">
			<div class="c-popup-block-options__inner">
				<a href="#" class="danger" @click.prevent="onDelete">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M9 2.75C8.30964 2.75 7.75 3.30964 7.75 4V4.75H16.25V4C16.25 3.30964 15.6904 2.75 15 2.75H12.5H9ZM17.75 4.75V4C17.75 2.48122 16.5188 1.25 15 1.25H12.5H9C7.48122 1.25 6.25 2.48122 6.25 4V4.75H4C3.58579 4.75 3.25 5.08579 3.25 5.5C3.25 5.91421 3.58579 6.25 4 6.25H7H17H20C20.4142 6.25 20.75 5.91421 20.75 5.5C20.75 5.08579 20.4142 4.75 20 4.75H17.75Z"
							fill="currentColor"
						/>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8C19 7.44772 18.5523 7 18 7H6C5.44772 7 5 7.44772 5 8ZM10.75 10C10.75 9.58579 10.4142 9.25 10 9.25C9.58579 9.25 9.25 9.58579 9.25 10V17C9.25 17.4142 9.58579 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17V10ZM14.75 10C14.75 9.58579 14.4142 9.25 14 9.25C13.5858 9.25 13.25 9.58579 13.25 10V17C13.25 17.4142 13.5858 17.75 14 17.75C14.4142 17.75 14.75 17.4142 14.75 17V10Z"
							fill="currentColor"
						/>
					</svg>

					<span> Delete </span>
				</a>
				<a href="#" @click.prevent="onCopy">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M20 18C21.1046 18 22 17.1046 22 16V7H17C15.8954 7 15 6.10457 15 5V0H10C8.89543 0 8 0.89543 8 2V5H9.58579C9.851 5 10.1054 5.10536 10.2929 5.29289L16.7071 11.7071C16.8946 11.8946 17 12.149 17 12.4142V18H20ZM16 0.482843V4.5C16 5.32843 16.6716 6 17.5 6H21.5172C21.6953 6 21.7846 5.78457 21.6586 5.65858L16.3414 0.341421C16.2154 0.215428 16 0.304662 16 0.482843ZM16 22C16 23.1046 15.1046 24 14 24H4C2.89543 24 2 23.1046 2 22V8C2 6.89543 2.89543 6 4 6H9V11C9 12.1046 9.89543 13 11 13H16V22ZM10 10.5V6.48284C10 6.30466 10.2154 6.21543 10.3414 6.34142L15.6586 11.6586C15.7846 11.7846 15.6953 12 15.5172 12H11.5C10.6716 12 10 11.3284 10 10.5Z"
							fill="currentColor"
						/>
					</svg>

					<span> Copy Public Ad link </span>
				</a>

				<TagSelector v-if="type !== 'board'" :adId="adId" @onEdit="onEdit"></TagSelector>
			</div>
		</Popup>
	</div>
</template>

<script>
import Popup from './index';
import TagSelector from '@swipekit/components/utils/tag-selector';

export default {
	components: {
		Popup,
		TagSelector,
	},

	data: function () {
		return {
			options: {
				active: false,
			},
			tags: [
				{
					name: 'All Ads',
					slug: 0,
				},
				{
					name: 'Active',
					slug: 1,
				},
				{
					name: 'Inactive',
					slug: 2,
				},
			],
			selectedActiveAds: 0,
		};
	},

	props: {
		active: {
			type: Boolean,
			default: false,
		},
		target: {},
		adId: {},
		type: {
			type: String,
		},
	},

	watch: {
		active: function () {
			setTimeout(() => {
				if (this.active) {
					this.options.active = true;
					this.focusInput();
				} else {
					this.options.active = false;
				}
			}, 2);
		},
		selectedActiveAds: function () {
			this.$emit('input', this.selectedActiveAds);
		},
	},

	methods: {
		onEdit: function () {
			this.$emit('onEdit');
		},
		focusInput: function () {
			let input = this.$el.querySelector('input');
			if (input) {
				setTimeout(() => {
					input.focus();
				}, 100);
			}
		},
		onCopy: function () {
			let adId = this.adId;

			navigator.clipboard.writeText(`https://swipekit.app/block/${adId}`);

			this.$store.dispatch('message/onAdLinkCopy');
		},
		onDelete: function () {
			this.$emit('onDelete', this.adId);
		},
		onClose: function () {
			if (!this.options.active) {
				return;
			}
			this.$emit('onClose');
		},
	},

	created: function () {},
};
</script>

<style lang="scss">
.c-popup-block-options {
	.c-popup {
		width: 280px;

		background: var(--color-bg-2);
		box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.4), inset 0 4px 6px 1px rgba(255, 255, 255, 0.2), var(--item-box-shadow);
		transition: all var(--transition-time) ease-out;

		&__arrow {
			color: var(--color-bg-2);
			top: -11px;
		}
	}
	article {
		//padding: var(--margin) var(--margin-lg);
	}

	&__inner {
		padding: var(--margin);

		a {
			width: 100%;
			display: flex;
			margin-top: var(--margin);
			padding: var(--margin);
			align-items: center;
			background-color: hsl(var(--color-bg-4), 80%, 94%);
			color: var(--color-font);
			border-radius: 5px;
			transition: all 120ms ease-out;
			text-decoration: none !important;

			svg {
				margin-right: var(--margin);
			}

			&:hover,
			&:active {
				background-color: var(--color-font);
				color: var(--color-bg-1);
			}

			&.danger {
				background-color: hsl(var(--hue-danger), 80%, 94%);
				color: var(--color-danger);

				&:hover {
					background-color: var(--color-danger);
					color: var(--color-bg-1);
				}
			}

			&:not(:last-child) {
				margin-top: 0;
			}
		}
	}
}
</style>
