<template>
  <div class="c-workspace-invoices">
    <div class="c-workspace-invoices__item c-workspace-invoices__item--header">
      <div class="c-workspace-invoices__column">
        <span> Invoice Number </span>
      </div>
      <div class="c-workspace-invoices__column">
        <span> Date issued </span>
      </div>
      <div class="c-workspace-invoices__column">
        <span> Actions </span>
      </div>
    </div>
    <div class="c-workspace-invoices__item" v-for="invoice in computedInvoices" :key="invoice.id">
      <div class="c-workspace-invoices__column">
        <span> {{ invoice.number }} {{ voided(invoice) }} </span>
      </div>
      <div class="c-workspace-invoices__column">
        <span> {{ formatDate(invoice.period_end) }} </span>
      </div>
      <div class="c-workspace-invoices__column">
        <button class="btn btn-box" @click="onDownload(invoice.id, invoice.number)">Download Invoice</button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@swipekit/lib/api';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';

export default {
  data: function () {
    return {
      processing: false,
      invoices: [],
    };
  },

  computed: {
    computedInvoices: function () {
      let invoices = this.invoices;

      let newInvoices = [];

      for (let i = 0; i < invoices.length; i++) {
        let invoice = invoices[i];

        newInvoices.push({
          number: invoice.number,
          id: invoice.id,
          period_start: invoice.period_start,
          period_end: invoice.period_end,
          paid: invoice.paid,
        });
      }

      return newInvoices;
    },
  },

  methods: {
    voided(invoice) {
      if (!invoice.paid) {
        return `(Voided)`;
      }
      return '';
    },
    async loadInvoices() {
      let invoices = await api.workspaces.getInvoices();

      this.invoices = invoices;
      this.processing = false;
    },
    async onDownload(invoiceId, invoiceNumber) {
      this.$store.dispatch('message/onInvoiceDownload');
      await api.workspaces.downloadInvoice(invoiceId, invoiceNumber);
    },
    formatDate: function (unix) {
      let date = fromUnixTime(unix);

      let str = format(date, `do LLL, yyy`);

      return str;
    },
  },

  created: function () {
    this.processing = true;
  },

  mounted: function () {
    this.loadInvoices();
  },
};
</script>

<style lang="scss">
.c-workspace-invoices {
  overflow: hidden;
  margin-bottom: var(--spacer-sm);
  border-radius: var(--border-radius);
  border: var(--color-bg-5) solid 1px;

  &__item {
    display: grid;
    grid-template-columns: 300px 300px auto;
    gap: 0px 0px;
    &:not(:last-child) {
      border-bottom: var(--color-bg-5) solid 1px;
    }

    &--header {
      background-color: var(--color-bg-3);
    }
  }

  &__column {
    display: flex;
    align-items: center;
    padding: var(--margin-lg);

    &:last-child:not(:first-child) {
      justify-content: right;
    }
  }

  .label {
    font-size: var(--font-size-xs);
    text-transform: uppercase;
    padding: var(--margin-sm) var(--margin);
    border-radius: var(--border-radius);
    border: var(--color-bg-4) solid 1px;
  }
}
</style>
