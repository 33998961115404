<template>
  <div class="c-form">
    <slot></slot>
    <div class="c-form__item" v-for="(field, key) in computedSchema" :key="key">
      <component
        :key="field.slug"
        :is="getComponent(field)"
        @input="update(key, $event)"
        :label="field.label"
        :value="value[key]"
        :options="field.options || []"
        :optionKey="field.optionKey || null"
        :hasError="hasError(key)"
        :errorMessage="errorMessages[key]"
        v-bind="{ ...field }"
      ></component>
    </div>
    <template v-if="computedErrors.length > 0">
      <InputError v-for="(error, index) in computedErrors" :message="error.message" :key="index"></InputError>
    </template>
    <InputButton :type="isDirty === true ? 'btn-primary' : ''" :buttonClass="buttonClass" :touched="touched" :processing="processing" :label="buttonText"></InputButton>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import parser from './parser';
import InputText from './input-text.vue';
import InputEmail from './input-email.vue';
import InputPassword from './input-password.vue';
import InputButton from './input-button.vue';
import InputButtonChoice from './input-button-choice.vue';
import InputError from './input-error.vue';
import InputChoice from './input-choice.vue';
import InputEditor from './input-editor.vue';
import InputBoards from './input-boards.vue';
import InputBoolean from './input-boolean.vue';
import InputPublicLink from './input-public-link.vue';
import InputTags from './input-tags';
import InputCheckboxSimple from './input-checkbox-simple';
import InputCheckboxGroup from './input-checkbox-group';
import InputRadioGroup from './input-radio-group';
import InputBoardsDropdown from './input-boards-dropdown';
import InputMultiple from './input-multiple';
import InputUpload from './input-upload';
import InputReadonly from './input-readonly';
import InputAddress from './input-address';
import InputSeats from './input-seats';
import InputTextarea from './input-textarea';
import InputNumber from './input-number';
import InputToken from './input-token';

export default {
  mixins: [validationMixin],

  components: {
    InputText,
    InputEmail,
    InputPassword,
    InputButton,
    InputButtonChoice,
    InputError,
    InputChoice,
    InputEditor,
    InputBoards,
    InputBoolean,
    InputPublicLink,
    InputTags,
    InputCheckboxSimple,
    InputCheckboxGroup,
    InputRadioGroup,
    InputBoardsDropdown,
    InputMultiple,
    InputUpload,
    InputReadonly,
    InputAddress,
    InputSeats,
    InputTextarea,
    InputNumber,
    InputToken,
  },

  validations: function () {
    return {
      form: parser(this.computedFlatSchema),
    };
  },

  data: function () {
    return {
      init: false,
      touched: false,
      form: {},
    };
  },

  watch: {},

  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object,
      default: function () {
        return {};
      },
    },
    value: { type: Object, required: true },
    buttonText: {
      type: String,
      default: 'Submit',
    },
    buttonClass: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  computed: {
    computedErrors: function () {
      let errors = this.errors || [];

      let arr = [];

      for (let i = 0; i < errors.length; i++) {
        let errorObj = errors[i].message;
        try {
          errorObj = JSON.parse(errorObj);
        } catch (err) {
          errorObj = {
            message: errors[i].message,
            type: errors[i].type || 'GENERIC',
          };
        }
        arr.push(errorObj);
      }

      return arr;
    },
    isDirty: function () {
      return this.touched;
    },
    isValid: function () {
      return false;
    },
    computedSchema: function () {
      let schema = this.schema;
      return schema;
    },
    computedFlatSchema: function () {
      let newSchema = this.computedSchema;
      return newSchema;
    },
    errorMessages() {
      const validations = this.$v.form;
      let schema = this.computedFlatSchema;
      return Object.keys(schema).reduce((messages, key) => {
        const rules = schema[key].validations || {};
        const rulesKeys = Object.keys(rules);
        const validator = validations[key];
        if (!validator) return messages;
        for (let rule of rulesKeys) {
          if (validator[rule] !== false) continue;
          messages[key] = rules[rule].message;
          return messages;
        }
        return messages;
      }, {});
    },
  },

  methods: {
    getComponent: function (field) {
      if (field.readonly) {
        return `InputReadonly`;
      }

      return field.component;
    },
    hasError: function (key) {
      if (this.$v.form[key]) {
        return this.$v.form[key].$error;
      } else {
        return false;
      }
    },
    getColumnClass: function (component) {
      let klass = 'col-12';
      if (component.properties && component.properties.columnSize) {
        klass = `col-${component.properties.columnSize}`;
      }
      return klass;
    },
    validate() {
      this.$v.$touch();
      this.announceStatus();
    },
    update: function (key, value) {
      if (this.init) {
        this.touched = true;
      }
      this.form[key] = value;
      if (this.$v.form[key]) {
        this.$v.form[key].$touch();
      }
      this.$emit('input', {
        ...this.value,
        [key]: value,
      });
      this.announceStatus();
    },
    announceStatus: function () {
      this.$emit('status', {
        invalid: this.$v.$invalid,
      });
    },
  },

  created() {
    for (const name in this.computedFlatSchema) {
      this.$set(this.form, name, null);
    }
    for (const name in this.value) {
      if (name in this.form) {
        this.$set(this.form, name, this.value[name]);
      }
    }
  },

  mounted: function () {
    this.$nextTick(() => {
      this.init = true;
    });
  },
};
</script>

<style>
.c-form {
}
</style>
