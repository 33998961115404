<template>
	<div class="c-wiggle">
		<svg viewBox="0 0 2000 1000" xmlns="http://www.w3.org/2000/svg" version="1.1">
			<g id="gTest"><path class="SamplePath" id="dynPath2" d="M600,500 C600,350 900,650 900,500" vector-effect="non-scaling-stroke" stroke-linecap="round" /></g>
		</svg>
	</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import anime from 'animejs';

export default {
	components: {},

	data: function () {
		return {
			limit: 6,
			stack: [],
		};
	},

	watch: {
		processing: function () {
			if (this.processing) {
			}
		},
	},

	props: {
		processing: {},
	},

	methods: {
		wiggle: function () {
			let p1 = { y: 350, x: 0 };
			let p2 = { y: 650, x: 0 };
			let len = 500;

			let svgPath = this.$el.querySelector('#dynPath2');

			// anime({
			// 	targets: p1,
			// 	y: [0, len],
			// 	easing: 'easeInOutSine',
			// 	direction: 'alternate',
			// 	duration: 250,
			// 	loop: true,
			// 	update: function () {
			// 		var path = `M0,500 C400,${p1.y} 600,${p2.y} 1000,500`;
			// 		svgPath.setAttribute('d', path);
			// 	},
			// });

			// anime(
			// 	{
			// 		targets: p2,
			// 		y: [len, 0],
			// 		easing: 'easeInOutSine',
			// 		direction: 'alternate',
			// 		duration: 250,
			// 		loop: true,
			// 	},
			// 	'+=250'
			// );
		},
	},

	mounted: function () {
		this.wiggle();
	},
};
</script>

<style lang="scss">
.c-wiggle {
	position: fixed;
	z-index: 1050;
	bottom: 0;
	left: calc(50% - 210px);
	width: 100%;

	padding: var(--margin-lg) 0;

	//pointer-events: none;

	svg {
		.Border {
			fill: none;
			stroke: blue;
			stroke-width: 1;
		}
		.Connect {
			fill: none;
			stroke: #888888;
			stroke-width: 2;
		}
		.SamplePath {
			fill: none;
			stroke: red;
			stroke-width: 5;
			vector-effect: non-scaling-stroke;
		}
		.EndPoint {
			fill: none;
			stroke: #888888;
			stroke-width: 2;
		}
		.CtlPoint {
			fill: #888888;
			stroke: none;
		}
		.AutoCtlPoint {
			fill: none;
			stroke: blue;
			stroke-width: 4;
		}
		.Label {
			text-anchor: middle;
			font-size: 22;
			font-family: Verdana;
		}
	}
}
</style>
