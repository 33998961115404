<template>
  <div class="c-manual">
    <PageHeader byline="Help & Support">
      <template v-slot:title> All your questions, answered </template>
    </PageHeader>
    <div class="c-manual__inner">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <!-- <article>
              <p class="article-text">Ran into snags? This is the place to get help.</p>
              <p class="article-text">View all our help documentation here.</p>
            </article> -->

            <div class="c-manual__category" v-for="category in categories" :key="category.name">
              <h3>{{ category.name }}</h3>

              <ol class="c-list-fancy">
                <li v-for="(post, i) in category.posts" :key="i">
                  <Accordion :active="false" :title="post.question">
                    <template v-slot:title>
                      <h4>
                        {{ post.question }}
                      </h4>
                    </template>
                    <template v-slot:body>
                      <article v-html="post.answer">
                        {{ post.question }}
                      </article>
                    </template>
                  </Accordion>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@swipekit/components/utils/page-header.vue';
import Accordion from '@swipekit/components/utils/accordion';

export default {
  name: 'Manual',
  components: {
    PageHeader,
    Accordion,
  },

  data: function () {
    return {
      processing: {
        upgrade: false,
      },
      helps: [],
    };
  },

  computed: {
    baseUrl: function () {
      return this.$store.state.config.baseUrl;
    },
    user: function () {
      return this.$store.getters['users/user'];
    },
    categories: function () {
      let helps = this.helps;

      let categories = {
        extension: {
          name: '🔌 Extension',
          posts: [],
        },
        webapp: {
          name: '💻 Webapp',
          posts: [],
        },
        'brand-tracker': {
          name: '📊 Brand Tracker',
          posts: [],
        },
        billing: {
          name: '💳 Billing & Operations',
          posts: [],
        },
        misc: {
          name: '🛟 Other issues',
          posts: [],
        },
      };

      for (let i = 0; i < helps.length; i++) {
        let help = helps[i];
        let cat = help.category;
        if (categories[cat]) {
          categories[cat].posts.push(help);
        } else {
          //categories.misc.posts.push(help);
        }
      }

      return categories;
    },
  },

  methods: {
    async upgrade() {
      this.processing.upgrade = true;
      await this.$store.dispatch('users/upgrade');
    },
    logout: function () {
      this.$store.dispatch('users/logout');
    },
  },

  created: function () {
    //this.$store.dispatch();

    this.$store.dispatch('app/getHelps').then((data) => {
      this.helps = data;
    });
  },
};
</script>

<style lang="scss">
.c-manual {
  padding-bottom: var(--spacer);

  .container {
    padding: 0 var(--spacer-sm);
    margin: 0 auto;
  }

  &__account {
    margin: var(--spacer) 0;
    padding: var(--spacer);
    border: var(--color-input-background) solid 1px;
    border-radius: var(--border-radius);
  }

  &__inner {
    padding-top: var(--spacer);

    h1 {
      margin-bottom: var(--spacer-sm);
      margin: 0;
      font-weight: 300;
      font-size: var(--font-size-xl);
      font-family: var(--font-family-fancy);
      line-height: 1;
    }

    article {
      padding: var(--margin) 0;
    }

    .c-accordion {
      h5 {
        text-transform: initial;
      }

      &__header {
        padding: var(--margin-lg);
        background-color: var(--color-bg-3);
        border-radius: var(--border-radius-lg);

        &:hover {
          background-color: var(--color-bg-4);
        }
      }
      &__body {
        &__inner {
          padding-bottom: 0;
        }
      }
    }
  }

  h5 {
    margin-bottom: 0;
  }

  .c-list-fancy {
    > li {
      &:before {
        top: 9px;
        left: 3px;
        font-family: var(--font-family-monospace);
        font-weight: 500;
      }
    }
  }

  &__category {
    padding: var(--spacer-sm) 0;
    margin-bottom: var(--spacer-sm);
    border-bottom: var(--color-bg-4) solid 1px;

    img {
      max-width: 520px;
      height: auto;
      border-radius: 10px;
      border: #000 solid 3px;
    }
  }

  ul {
    margin-top: 0;

    li {
      margin-bottom: var(--margin);
    }
  }
}
</style>
