<template>
  <div class="form-group c-input-token">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <div class="code-inputs">
      <input
        v-for="(digit, index) in digits"
        :key="index"
        class="form-control"
        type="text"
        maxlength="2"
        v-model="digits[index]"
        @input="onInput(index, $event)"
        @paste="onPaste($event)"
        :ref="'input' + index"
      />
    </div>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription v-if="description" :message="description"></InputDescription>
  </div>
</template>

<script>
import InputError from './input-error';
import InputDescription from './input-description';
import slugify from '@swipekit/lib/slugify.js';

export default {
  components: {
    InputError,
    InputDescription,
  },

  data() {
    return {
      digits: Array(6).fill(''),
    };
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    validation: {},
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },

  watch: {
    value(newValue) {
      if (newValue && newValue.length === 6) {
        this.digits = newValue.split('');
      }
    },
  },

  computed: {
    slug() {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },

    token() {
      return this.digits.join('');
    },
  },

  methods: {
    onInput: function (i, e) {
      console.log('trigger');
      if (e.target.value.length > 1) {
        this.digits[i] = e.target.value[0];
        if (typeof this.digits[i + 1] !== undefined && i < this.digits.length - 1) {
          let nextVal = e.target.value[1];
          this.digits[i + 1] = nextVal;
          this.$refs[`input${i + 1}`][0].focus();
        }
        e.preventDefault();
      }
    },
    onPaste: function (e) {
      const paste = event.clipboardData.getData('text');
      if (/^\d{6}$/.test(paste)) {
        this.digits = paste.split('');
        this.$emit('input', this.token);
        e.preventDefault();
      }
    },
  },

  mounted() {
    if (this.value && this.value.length === 6) {
      this.digits = this.value.split('');
    }
  },
};
</script>

<style lang="scss">
.c-input-token {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  .code-inputs {
    display: flex;
    gap: var(--spacer-sm);
    margin: 0 auto;
    max-width: 400px;
  }

  .form-control {
    height: 48px;
    font-weight: 500;
    text-align: center;
  }

  // .code-input {
  //   width: 2rem;
  //   height: 2rem;
  //   text-align: center;
  //   font-size: 1.5rem;
  //   border: 1px solid #ccc;
  //   border-radius: 4px;
  // }
}
</style>
