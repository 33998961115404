<template>
	<div class="c-workspace-pricing" v-if="prices">
		<div class="c-workspace-pricing__trial" v-if="workspace && workspace.plan && workspace.plan === 'DEACTIVATED'">
			<p>Heads up - Your trial period has ended.</p>

			<!-- <template v-if="workspace.meta.allowReviewTrialExtension && accountCreationDaysAgo < 24">
				<p>
					<a class="btn btn-primary" href="#" @click.prevent="onExtendTrial"> Extend your trial </a>
				</p>
			</template> -->
		</div>
		<PricingTable v-if="prices" @event="onEvent" @onUpgrade="upgrade" :prices="prices"></PricingTable>
	</div>
</template>

<script>
import api from '@swipekit/lib/api';

import PricingTable from '@swipekit/components/pricing-table';

import parseISO from 'date-fns/parseISO';
import differenceInDays from 'date-fns/differenceInDays';

export default {
	components: {
		PricingTable,
	},

	data: function () {
		return {
			prices: null,
			processing: {
				upgrade: false,
			},
		};
	},

	computed: {
		accountCreationDaysAgo: function () {
			let workspace = this.workspace;

			if (!workspace) {
				return;
			}

			let createdAt = workspace.createdAt;

			let trialDate = parseISO(createdAt);

			let dayDifference = differenceInDays(new Date(), trialDate);

			return dayDifference;
		},
		workspace: function () {
			return this.$store.getters['workspaces/all'];
		},
		baseUrl: function () {
			return this.$store.state.config.baseUrl;
		},
		pricePerMonth: function () {
			let price = this.currentPrice;

			let p = 0;

			if (price.frequency !== 'monthly') {
				p = price.price / 12;
			} else {
				p = price.price;
			}

			p = Math.floor(p);

			return p;
		},
		currentPrice: function () {
			let f = this.frequency;

			let prices = this.prices;

			let currentPrice = {
				price: 30,
				frequency: 'monthly',
			};

			for (let i = 0; i < prices.length; i++) {
				if (prices[i].frequency === f) {
					currentPrice = prices[i];
					break;
				}
			}

			return currentPrice;
		},
	},

	methods: {
		onExtendTrial: function () {
			this.onEvent({});
			this.$store.dispatch('app/setRewardTrialExtension', true);
		},
		onEvent: function () {
			let payload = {
				name: `user.trialextensionviewed`,
			};

			api.api.track(payload);
		},
		changeFrequency: function (f) {
			this.frequency = f;
		},
		async upgrade(obj) {
			this.processing.upgrade = true;
			await this.$store.dispatch('users/upgrade', obj);
		},
	},

	mounted: function () {
		api.api.getPrices().then((data) => {
			console.log(data);
			if (data) {
				this.prices = data;
			}
		});
	},
};
</script>

<style lang="scss">
.c-workspace-pricing {
	padding: var(--spacer) 0;

	&__trial {
		max-width: 420px;
		margin: 0 auto;
		padding: var(--margin-lg);
		margin-bottom: var(--spacer);

		background-color: var(--color-bg-3);
		border-radius: var(--border-radius);
		border: 2px solid var(--color-primary);

		text-align: center;

		p:last-of-type {
			margin-bottom: 0;
		}
	}
}
</style>
