<template>
  <div class="c-profile" v-if="isAuth">
    <PageHeader byline="Profile & Settings">
      <template v-slot:title> Profile </template>
    </PageHeader>

    <div class="container" v-if="workspace">
      <div class="row">
        <div class="col-12">
          <!-- Tabs -->
          <div class="c-profile__tabs">
            <router-link :to="`/profile`">
              <span>Profile Settings</span>
              <p>Update your Profile's settings</p>
            </router-link>
            <router-link :to="`/profile/workspace`">
              <span>Workspace Settings</span>
              <p>Update your Workspace's settings & invite users</p>
            </router-link>
            <router-link :to="`/profile/billing`">
              <span>Billing Portal</span>
              <p>Upgrade to Pro / Access your billing portal from here</p>
            </router-link>
          </div>
          <!-- Tabs END -->

          <div class="c-profile__inner">
            <router-view></router-view>

            <template v-if="!workspace.activated">
              <h5>Heads up</h5>
              <p>You haven't verified your email. Please verify to use all features of your account.</p>
              <FormVerificationEmail v-if="!hasResentVerificationEmail" @onSubmit="hasResentVerificationEmail = true"></FormVerificationEmail>

              <br />
              <br />
            </template>

            <template v-if="showProfile">
              <Box>
                <template v-slot:header>
                  <h3>Personal Settings</h3>
                </template>
                <template v-slot:body>
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-6">
                        <h4>Profile settings</h4>
                        <p>Update your personal settings like name and profile picture.</p>
                      </div>
                      <div class="col-md-6">
                        <FormSettings></FormSettings>
                      </div>
                    </div>
                  </div>

                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-6">
                        <h4>Email settings</h4>
                        <p>Update your email from here.</p>
                      </div>
                      <div class="col-md-6">
                        <FormEmailUpdate></FormEmailUpdate>
                      </div>
                    </div>
                  </div>

                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-6">
                        <h4>Password settings</h4>
                        <p>Update your account's password from here.</p>
                      </div>
                      <div class="col-md-6">
                        <FormPasswordUpdate> </FormPasswordUpdate>
                      </div>
                    </div>
                  </div>
                </template>
              </Box>

              <br />
              <br />

              <Box>
                <template v-slot:header>
                  <h3>Stats</h3>
                </template>
                <template v-slot:body>
                  <WorkspaceUserStats></WorkspaceUserStats>
                </template>
              </Box>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@swipekit/components/utils/page-header.vue';

import InputButton from '@swipekit/components/form/input-button';
import FormWorkspaceUpdate from '@swipekit/components/form/form-workspace-update';
import FormInviteUsers from '@swipekit/components/form/form-invite-users';
import FormSettings from '@swipekit/components/form/form-settings';
import FormEmailUpdate from '@swipekit/components/form/form-email-update';
import FormPasswordUpdate from '@swipekit/components/form/form-password-update';

import Settings from '@swipekit/components/app/settings';

import Workspace from '@swipekit/components/workspace';

import api from '@swipekit/lib/api';

import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

import Box from '@swipekit/components/utils/box';
import FormVerificationEmail from '@swipekit/components/form/form-verification-email';

import WorkspaceUserStats from '@swipekit/components/workspace/user-stats';

export default {
  name: 'Home',
  components: {
    PageHeader,
    InputButton,
    FormWorkspaceUpdate,
    FormInviteUsers,
    FormSettings,
    FormEmailUpdate,
    FormVerificationEmail,
    Workspace,
    Settings,
    Box,
    WorkspaceUserStats,
    FormPasswordUpdate,
  },

  data: function () {
    return {
      processing: {
        upgrade: false,
      },

      hasResentVerificationEmail: false,
    };
  },

  computed: {
    workspace: function () {
      return this.$store.getters['workspaces/all'];
    },
    isPaid: function () {
      return this.$store.getters['users/isPaid'];
    },
    trialStartedDifference: function () {
      let diff = this.$store.getters['users/trialStartedDifference'];

      return diff;
    },
    isTrialOver: function () {
      return this.$store.getters['users/isTrialOver'];
    },
    baseUrl: function () {
      return this.$store.state.config.baseUrl;
    },
    user: function () {
      return this.$store.getters['users/user'];
    },
    isAuth: function () {
      return this.$store.getters['users/isAuth'];
    },
    workspace: function () {
      return this.$store.getters['workspaces/all'];
    },
    adsSaved: function () {
      let ads = this.$store.getters['ads/filtered'];

      return ads.length;
    },
    boardsSaved: function () {
      let boards = this.$store.getters['boards/filtered'];

      return boards.length;
    },
    computedMemberSince: function () {
      let createdAt = this.user.createdAt;

      let date = parseISO(createdAt);

      let str = format(date, 'do MMM, yyyy');

      return str;
    },
    computedPlan: function () {
      if (!this.workspace) {
        return '';
      }
      let plan = this.workspace.plan;

      if (!plan) {
        return '';
      }

      plan = plan.toLowerCase();

      plan = plan.charAt(0).toUpperCase() + plan.slice(1);

      return plan;
    },
    showProfile: function () {
      let route = this.$route;

      if (route.fullPath === '/profile') {
        return true;
      }

      return false;
    },
  },

  methods: {
    async gotoBillingPortal() {
      let res = await api.users.portal();

      if (res.portalUrl) {
        window.open(res.portalUrl, '_blank').focus();
      }
    },
    async upgrade() {
      this.processing.upgrade = true;
      await this.$store.dispatch('users/upgrade', 'monthly');
    },
    logout: function () {
      this.$store.dispatch('users/logout');
    },
  },

  mounted: function () {
    api.users.stats().then((data) => {});
  },
};
</script>

<style lang="scss">
.c-profile {
  padding-bottom: var(--spacer);

  .container {
    padding: 0 var(--spacer-sm);
    margin: 0 auto;
  }

  .c-table > table {
    border-radius: 10px;
    overflow: hidden;
    display: block;
    border: var(--color-bg-5) solid 1px;
  }

  &__account {
    padding: var(--spacer);
    border: var(--color-input-background) solid 1px;
    border-radius: var(--border-radius);

    li {
      margin-bottom: var(--margin);
    }

    .c-spinner {
      margin-right: var(--margin-lg);
    }
  }

  &__inner {
    padding: var(--spacer) 0;
  }

  &__tabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: var(--margin-lg);

    a {
      display: inline-block;
      padding: var(--margin-lg) var(--spacer-sm);
      font-weight: 450;
      color: var(--color-font) !important;
      background-color: var(--color-bg-1);
      border-bottom: var(--color-bg-4) solid 3px;
      text-decoration: none !important;

      &:hover,
      &.router-link-exact-active {
        background-color: hsla(var(--p), 65%, 65%, 0.1);
        border-bottom-color: var(--color-primary);
      }

      p {
        margin-bottom: 0;
        opacity: 0.75;
        font-size: var(--font-size-sm);
        font-weight: 400;
      }
    }
  }

  .c-table {
    a {
      word-break: break-all;
    }

    tr {
      td:first-child {
        opacity: 0.85;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &__tabs {
      p {
        display: none;
      }
    }

    .c-table {
      tr {
        grid-template-columns: 1fr;
        border-bottom: var(--color-bg-5) solid 1px;
      }
      td {
        border-bottom: none;
      }
    }
  }
}
</style>
