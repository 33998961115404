<template>
  <form class="c-form-verificationtoken" @submit.prevent="onSubmit">
    <Form v-model="currentForm" ref="form" :schema="schema" @status="saveStatus" buttonText="Verify token" :processing="processing" :errors="errors"></Form>
  </form>
</template>

<script>
import Form from './index.vue';

let schema = {
  token: {
    component: 'InputToken',
    //label: 'Your company name',
    //description: 'Eg: your company name',
    //placeholder: 'Acme inc',
  },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      schema: schema,
      processing: false,
      errors: [],
      status: null,

      currentForm: {
        token: '',
      },
    };
  },

  computed: {},

  methods: {
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.currentForm;

      console.log(form);

      const res = await this.$store.dispatch('workspaces/verifyToken', form.token).catch((err) => {
        console.log(err);
      });

      this.processing = false;

      if (!res) {
        this.errors.push({
          message: `This token is invalid.`,
        });
        return;
      }

      this.$emit('onSubmit');
    },
  },

  created: function () {},

  mounted: function () {},
};
</script>

<style lang="scss">
.c-form-verificationtoken {
  max-width: 400px;
  margin: 0 auto;
  .c-input-button {
    .btn {
      width: 100%;
      border-radius: 6px;
      max-height: 44px;
      justify-content: center;

      margin-right: 0;

      .c-spinner {
        width: 24px;
        height: 24px;
        margin-right: var(--margin);
        margin-left: 0;
      }
    }
  }
}
</style>
