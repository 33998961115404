<template>
  <div class="c-settings">
    <h3>Workspace</h3>
    <Workspace></Workspace>
    <h3>Your profile</h3>
    <Box>
      <template v-slot:header>
        <h3>Profile Settings</h3>
      </template>
      <template v-slot:body>
        <FormSettings></FormSettings>
      </template>
    </Box>
  </div>
</template>

<script>
import Workspace from '@swipekit/components/workspace';
import Box from '@swipekit/components/utils/box';
import FormSettings from '@swipekit/components/form/form-settings';

export default {
  components: {
    Workspace,
    Box,
    FormSettings,
  },
};
</script>

<style lang="scss">
.c-settings {
  padding: var(--spacer) 0;

  h3 {
    padding-bottom: var(--margin-lg);
    margin-bottom: var(--spacer-sm);
    border-bottom: var(--color-bg-4) solid 1px;
  }

  .c-box {
    margin-bottom: var(--spacer);
  }
}
</style>
