<template>
  <div class="c-page-check">
    <Page :i="0">
      <div v-if="processingActivation">
        <h1>Hang on, activating your account.</h1>
        <div class="c-spinner"></div>
      </div>
      <div v-else>
        <h1>Please check your email inbox</h1>
        <p>An email has been sent at {{ user.email }}. Click on the link inside that email to verify your Swipekit account.</p>
        <p>Alternatively, enter the 6 digit code found in the email:</p>

        <FormVerificationtoken @onSubmit="onSubmit"></FormVerificationtoken>

        <!-- <button class="btn btn-primary" @click="onLogout">Logout</button> -->
      </div>
    </Page>
  </div>
</template>

<script>
import Page from './page';
import ButtonCta from './button-cta.vue';
import FormVerificationtoken from '@swipekit/components/form/form-verificationtoken';

export default {
  components: {
    Page,
    ButtonCta,
    FormVerificationtoken,
  },

  props: {
    processingActivation: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    user: function () {
      return this.$store.getters['users/user'];
    },
  },

  methods: {
    onSubmit: function () {
      this.$emit('onNext');
    },
  },
};
</script>

<style lang="scss">
.c-page-check {
  .c-spinner {
    display: block;
    margin: 0 auto;
    width: var(--spacer);
    height: var(--spacer);
    border-bottom-color: #000;
    border-width: 4px;
  }
}
</style>
