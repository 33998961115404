<template>
  <div class="c-page-fb">
    <Page :i="1">
      <!-- <span class="c-byline"> Tutorial #1: Facebook Ad Library </span> -->
      <h1>
        Welcome to<br />
        Swipekit, {{ user.name }}👋
      </h1>
      <article class="article-text text-center">
        <p>Let's start from the basics — saving Ads.</p>
      </article>
      <div class="c-page-fb__img">
        <article>
          <p>Swipekit Chrome extension will add a Save button to all ads in the Facebook ad library.</p>
          <p>Clicking this will save the ad permanently in your Swipekit library.</p>
        </article>
        <div class="c-page-fb__img-wrapper">
          <svg width="284" height="250" viewBox="0 0 284 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M225.55 110.814L235.156 103.928C241.143 99.8785 245.855 95.8502 249.972 92.8092L255.57 88.5933L259.962 85.0139C262.571 82.9543 264.611 81.4461 266.2 80.3809C272.534 76.0757 271.68 78.9395 272.708 80.4279C273.736 81.9163 276.215 81.5903 270.923 87.9441C269.59 89.5015 267.777 91.4919 265.332 94.0134L261.192 98.2685L255.737 103.05C251.762 106.508 246.925 110.828 240.842 115.18L231.036 122.406C227.421 124.91 223.502 127.313 219.313 130.072C199.501 142.551 178.598 152.755 156.888 160.543C133.352 168.935 108.971 174.357 84.1602 176.715C64.4608 178.713 44.5328 178.42 24.6744 175.84C18.7412 175.14 12.8292 174.058 6.9665 172.599C2.80144 171.626 0.544921 170.878 0.593353 170.486C0.717127 168.158 37.6879 174.527 83.3118 167.437C107.374 163.902 130.96 157.673 153.739 148.837C174.683 140.666 194.897 130.437 214.167 118.259L225.468 110.898"
              fill="#5449E7"
            />
            <path
              d="M257.032 78.0856C249.916 76.7264 243.559 75.3225 238.32 74.03C227.62 71.4087 221.076 69.0175 221.218 67.5466C221.36 66.0757 228.106 65.7575 239.003 66.4819C244.436 66.8391 250.86 67.4504 258.022 68.2697C261.642 68.7292 265.178 69.1237 269.377 69.7752C272.066 70.1178 274.662 71.2982 276.882 73.1886C278.409 74.6091 279.56 76.4981 280.189 78.6166C280.77 80.6541 280.962 82.7903 280.751 84.8743C280.354 87.6761 279.783 90.4314 279.043 93.1195C278.541 95.5445 277.825 97.8988 277.185 100.204C275.873 104.804 274.568 109.221 273.19 113.355C270.458 121.668 267.622 128.91 265.021 134.86C259.789 146.75 255.556 153.343 254.522 152.668C253.489 151.992 255.71 144.102 259.394 131.662C261.24 125.425 263.393 118.179 265.653 109.783C266.795 105.682 267.927 101.319 269.057 96.8065L270.736 89.9586C271.297 87.8763 271.738 85.747 272.056 83.5842C272.121 82.0137 272.117 82.3457 271.678 81.7928C270.594 80.9528 269.363 80.4177 268.091 80.2333C264.293 79.5299 260.587 78.8568 257.109 78.1852"
              fill="#5449E7"
            />
          </svg>

          <img src="/images/ad-example.png" />
        </div>
        <!-- <a @click="onSave" href="#" :class="['btn btn-primary', { saved: saved === true }]">
          <span v-if="processing" class="c-spinner"> </span>
          <span v-if="!saved">Save</span>
          <span v-if="saved">Saved, View in App</span>
        </a> -->
      </div>

      <ButtonCta :timer="3" @onClick="onClick">
        <span v-if="processing" class="c-spinner"> </span>
        <span> Got it </span>
      </ButtonCta>
    </Page>
  </div>
</template>

<script>
import Page from './page';
import ButtonCta from './button-cta.vue';
import Showcase from './showcase';

import api from '@swipekit/lib/api';

export default {
  components: {
    Page,
    ButtonCta,
    Showcase,
  },

  data: function () {
    return {
      saved: false,
      processing: false,
    };
  },

  computed: {
    user: function () {
      return this.$store.getters['users/user'];
    },
  },

  methods: {
    async onClick() {
      await this.onSave();
    },
    async onSave() {
      if (this.processing) {
        return;
      }
      this.processing = true;

      let res = await api.users.saveOnboardingAd().catch((err) => {
        console.log(err);
      });

      this.saved = true;
      this.processing = false;

      this.$emit('onNext', res);
    },
  },
};
</script>

<style lang="scss">
.c-page-fb {
  &__img {
    position: relative;
    margin: var(--spacer-lg) 0;
    width: 100%;
    aspect-ratio: 1153 / 799;
    display: grid;
    grid-template-columns: 60% 40%;

    &-wrapper {
      position: relative;
      width: 243px;
      height: 497px;
      transform: rotate(4deg);

      svg {
        position: absolute;
        top: 40%;
        left: -5%;
        width: 96px;
        height: 70px;
        transform: scale(1.25);
      }
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      border: solid 3px rgba(255, 255, 255, 1);
      border-radius: 6px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.075), 0 2px 2px 0 rgba(0, 0, 0, 0.05);
    }

    article {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: var(--spacer-sm);
    }
  }

  .c-spinner {
    margin-right: var(--margin-lg);
  }

  .article-text {
    span {
      font-family: var(--font-family-heading);
      text-transform: uppercase;
      font-weight: 600;
      font-style: italic;
      font-size: 14px;
    }
  }
}
</style>
