<template>
	<div class="c-pricing-table">
		<div class="c-pricing-table__selector">
			<a href="#" :class="[{ active: frequency === 'monthly' }]" @click.prevent="changeFrequency('monthly')"> Monthly pricing </a>
			<a href="#" :class="[{ active: frequency === 'annual' }]" @click.prevent="changeFrequency('annual')"> Annual pricing </a>
		</div>
		<div class="c-pricing-table__body">
			<div class="c-pricing-table__card" v-for="(card, i) in cards" :key="i">
				<div class="c-pricing-table__card__header">
					<h5>{{ card.name }}</h5>
					<h3>${{ getCardPrice(card) }} <span> per month </span></h3>
					<article class="article-text" v-if="false">
						{{ card.description }}
					</article>
					<p v-if="frequency === 'annual'">Save ${{ getDiscount(card) }} on the annual plan.</p>
				</div>
				<div class="c-pricing-table__card__body">
					<h6>Features</h6>
					<ul>
						<li class="list-title">
							<strong>Track upto {{ card.trackerLimit }} brands</strong>
							<strong>Invite upto {{ card.seats - 1 }} team members</strong>
						</li>
					</ul>
					<ul v-for="(category, j) in categories" :key="j">
						<li class="list-title">
							<strong>{{ category.name }}</strong>
						</li>

						<li v-for="(feature, k) in category.features" :key="k">
							<svg v-if="card.features.includes(feature)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7 13L10 16L17 8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
							<svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M8 8L16 16M16 8L8 16" stroke="red" stroke-width="2" stroke-linecap="round" />
							</svg>

							<span>
								{{ feature }}
							</span>
						</li>
					</ul>
				</div>
				<div v-if="footer" class="c-pricing-table__card__footer">
					<small> All prices are in USD </small>
					<small> You will be charged upfront on the annual plan. </small>
					<small> By upgrading, you agree to <a href="https://swipekit.app/terms" target="_blank">Swipekit's terms.</a> </small>

					<button @click="onUpgrade(card)" type="button" class="btn btn-lg btn-primary btn-box">
						<div v-if="processing" class="c-spinner"></div>
						<span>Upgrade to {{ card.name }}</span>
					</button>
				</div>
			</div>
			<!-- <div class="c-pricing-table__card">
        <div class="c-pricing-table__card__header">
          <h6>Custom plan</h6>
          <h3>Custom plan for larger agencies/brands</h3>
          <span> Min $5000 annual spend gurantee </span>
        </div>
      </div> -->
		</div>
	</div>
</template>

<script>
export default {
	data: function () {
		return {
			processing: false,
			frequency: 'annual',
			plan: 'Monthly Pro plan',
			categories: [
				{
					name: 'Brand Tracker',
					features: ['Track brands', 'Get insights on tracked brands', 'Save scraped brand ads directly from Swipekit'],
				},
				{
					name: 'Save Ads',
					features: ['Save Unlimited Ads', 'Save Ads from the Facebook Ad library', 'Save Ads from Tiktok Creative Center', 'Save Ads from Tiktok Ad Library', 'Save Ads from the Linkedin Ad Library'],
				},
				{
					name: 'Discover winning Ads',
					features: [
						'Access Swipekit multiplayer(over 500k Ads)',
						'Track how long Ads are running(Facebook only)',
						'View Ad reach and Ad spend estimates(Facebook only)',
						'View landing page screenshots',
						'View Ad Transcript',
					],
				},
				{
					name: 'Organize your library',
					features: ['Create & share unlimited boards', 'Share unlimited Ads'],
				},
				{
					name: 'Other',
					features: ['Priority support(via in-app chat widget)'],
				},
			],
		};
	},

	props: {
		prices: {},
		footer: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		cards: function () {
			let cards = [
				{
					name: 'Basic plan',
					description: 'Best for solo marketers and freelancers looking for a reliable swipe tool',
					trackerLimit: 5,
					seats: 5,
					features: [
						'Track brands',
						'Get insights on tracked brands',
						'Save scraped brand ads directly from Swipekit',
						'Save Unlimited Ads',
						'Save Ads from the Facebook Ad library',
						'Save Ads from Tiktok Creative Center',
						'Save Ads from Tiktok Ad Library',
						'Save Ads from the Linkedin Ad Library',
						'Access Swipekit multiplayer(over 500k Ads)',
						'Track how long Ads are running(Facebook only)',
						'View Ad reach and Ad spend estimates(Facebook only)',
						'Create & share unlimited boards',
						'Share unlimited Ads',
						'View landing page screenshots',
						'View Ad Transcript',
					],
				},

				{
					name: 'Plus plan',
					description: 'Best for small brands and up-coming store owners',
					trackerLimit: 10,
					seats: 10,
					features: [
						'Track brands',
						'Get insights on tracked brands',
						'Save scraped brand ads directly from Swipekit',
						'Save Unlimited Ads',
						'Save Ads from the Facebook Ad library',
						'Save Ads from Tiktok Creative Center',
						'Save Ads from Tiktok Ad Library',
						'Save Ads from the Linkedin Ad Library',
						'Access Swipekit multiplayer(over 500k Ads)',
						'Track how long Ads are running(Facebook only)',
						'View Ad reach and Ad spend estimates(Facebook only)',
						'Create & share unlimited boards',
						'Share unlimited Ads',
						'View landing page screenshots',
						'View Ad Transcript',
						'Priority support(via in-app chat widget)',
					],
				},

				{
					name: 'Agency plan',
					description: 'Best for Agencies, bigger brands & store owners looking to find and generate high performance creatives.',
					trackerLimit: 15,
					seats: 15,
					features: [
						'Track brands',
						'Get insights on tracked brands',
						'Save scraped brand ads directly from Swipekit',
						'Save Unlimited Ads',
						'Save Ads from the Facebook Ad library',
						'Save Ads from Tiktok Creative Center',
						'Save Ads from Tiktok Ad Library',
						'Save Ads from the Linkedin Ad Library',
						'Access Swipekit multiplayer(over 500k Ads)',
						'Track how long Ads are running(Facebook only)',
						'View Ad reach and Ad spend estimates(Facebook only)',
						'Create & share unlimited boards',
						'Share unlimited Ads',
						'View landing page screenshots',
						'View Ad Transcript',
						'Priority support(via in-app chat widget)',
					],
				},
			];
			return cards;
		},
		discount: function () {
			let price = this.getPrice();

			return 240;
		},
		currentPrice: function () {
			let price = this.getPrice();

			return price;
		},
	},

	methods: {
		getDiscount: function (card) {
			let frequency = 'monthly';

			let prices = this.prices;

			let price = null;

			for (let i = 0; i < prices.length; i++) {
				let p = prices[i];
				if (p.name.includes(card.name) && p.frequency === frequency) {
					price = p;
					break;
				}
			}

			if (!price) {
				return null;
			}

			return price.price * 4;
		},
		getCardPrice: function (card) {
			let frequency = this.frequency;

			let prices = this.prices;

			let price = null;

			for (let i = 0; i < prices.length; i++) {
				let p = prices[i];
				if (p.name.includes(card.name) && p.frequency === frequency) {
					price = p;
					break;
				}
			}

			if (price) {
				return this.formatPrice(price);
			}

			return null;
		},
		formatPrice: function (price, frequency) {
			if (!frequency) {
				frequency = price.frequency;
			}
			let p = price.price;
			if (frequency === 'annual') {
				p = Math.floor(price.price / 12);
			}
			return p;
		},
		getPrice: function (card) {
			let frequency = this.frequency;

			let prices = this.prices;

			let price = null;

			let tag = `${frequency} ${card.name.toLowerCase()}`;

			for (let i = 0; i < prices.length; i++) {
				let p = prices[i];

				if (p.label === tag) {
					price = p;
					break;
				}
			}

			return price;
		},
		onUpgrade: function (card) {
			let price = this.getPrice(card);

			if (this.processing) {
				return false;
			}
			this.processing = true;
			this.$emit('event', {
				name: 'User opened checkout page',
				frequency: this.frequency,
				price: price,
			});
			this.$emit('onUpgrade', {
				plan: price.plan,
				frequency: this.frequency,
				priceId: price.priceId,
			});

			setTimeout(() => {
				this.processing = false;
			}, 4000);
		},
		changeFrequency: function (f) {
			this.$emit('event', {
				name: 'User changed pricing frequency',
				frequency: f,
			});
			this.frequency = f;
		},
	},
};
</script>

<style lang="scss">
.c-pricing-table {
	&__selector {
		display: grid;
		grid-template-columns: min-content min-content;
		justify-content: center;
		align-items: center;
		grid-column-gap: var(--margin);
		margin-bottom: var(--spacer);

		> a {
			display: inline-block;
			padding: var(--margin) var(--margin-lg);
			border-radius: 99px;
			background-color: var(--color-bg-3);
			white-space: nowrap;
			user-select: none;
			transition: all var(--transition-time) linear;
			color: var(--color-font);
			font-weight: 500;
			font-feature-settings: 'case' 0, 'dlig' 0, 'frac' 0, 'dnom' 0, 'numr' 0, 'subs' 0, 'sups' 0, 'tnum', 'zero' 0, 'ss01', 'ss02' 0, 'ss03' 0, 'ss04', 'ss05' 0, 'ss06' 0, 'ss07' 0, 'ss08' 0,
				'cv01' 0, 'cv02' 0, 'cv03' 0, 'cv04' 0, 'cv05' 0, 'cv06' 0, 'cv07' 0, 'cv08' 0, 'cv09' 0, 'cv10' 0, 'cv11' 0, 'cv12' 0, 'cv13' 0, 'cpsp' 0, 'c2sc' 0, 'salt' 0, 'aalt' 0, 'calt', 'ccmp', 'locl',
				'kern';

			&.active {
				color: var(--color-primary);
				background-color: hsl(var(--p), 60%, 95%);
				text-decoration: none;
			}

			&:hover,
			&:active {
				color: var(--color-primary);
				background-color: hsl(var(--p), 60%, 95%);
				text-decoration: none;
			}
		}
	}

	&__body {
		display: grid;
		justify-content: center;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: var(--spacer-sm);
	}

	&__card {
		//width: 400px;
		border-radius: var(--border-radius);
		box-shadow: 0 0 0 1px rgba(17, 24, 28, 0.08), 0 1px 2px -1px rgba(17, 24, 28, 0.08), 0 2px 4px rgba(17, 24, 28, 0.04);

		ul {
			margin: 0;
			list-style: none;
			padding-left: 0;

			li {
				display: grid;
				grid-template-columns: 24px 1fr;
				margin-bottom: var(--margin);
				font-size: var(--font-size-sm);

				&.list-title {
					display: block;

					strong {
						margin-top: var(--margin-lg);
						display: block;
						font-weight: 500;
					}
				}

				// span {
				//   text-decoration: underline;
				//   text-decoration-color: var(--color-success);
				// }

				svg {
					color: var(--color-success);
				}
			}
		}

		h6,
		h5 {
			color: var(--color-primary);
		}

		.article-text {
			opacity: 0.8;
			margin-top: var(--margin);
			margin-bottom: var(--margin);
		}

		h3 {
			font-family: var(--font-family-body);
			margin-bottom: 0;

			> span {
				opacity: 0.75;
				font-size: var(--font-size-sm);
			}
		}

		&__header {
			padding: var(--margin-lg);
			padding-bottom: 0;
			//border-bottom: var(--color-bg-4) solid 1px;

			> p {
				margin: 0;
				margin-top: var(--margin);
				font-size: var(--font-size-sm);
				font-weight: 500;
				color: var(--color-secondary);
			}
		}

		&__body {
			padding: var(--margin-lg);
		}

		&__footer {
			padding: var(--margin-lg);
			border-top: var(--color-bg-4) solid 1px;

			pre {
				color: #000;
			}

			small {
				display: block;
				font-size: var(--font-size-xs);
				opacity: 0.75;
			}

			.btn {
				margin-top: var(--margin-lg);
				width: 100%;

				.c-spinner {
					margin-right: var(--margin);
				}
			}
		}
	}

	@media screen and (max-width: 520px) {
		&__card {
			width: 100%;
		}

		&__body {
			display: block;
		}
	}
}
</style>
