<template>
	<div class="c-page-header">
		<div class="c-page-header__bg">
			<svg width="100%" height="100%">
				<pattern id="pattern-circles" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse" patternContentUnits="userSpaceOnUse">
					<circle id="pattern-circle" cx="10" cy="10" r="1.6257413380501518" fill="currentColor"></circle>
				</pattern>

				<rect id="rect" x="0" y="0" width="100%" height="100%" fill="url(#pattern-circles)"></rect>
			</svg>
		</div>
		<div class="c-page-header__inner">
			<span class="c-byline" v-if="byline && !breadcrumbs">
				{{ byline }}
			</span>
			<div class="c-page-header__breadcrumbs" v-if="breadcrumbs">
				<component class="c-byline" v-for="(breadcrumb, i) in computedBreadcrumbs" :key="i" :is="breadcrumb.type" :to="breadcrumb.url">
					{{ breadcrumb.name }}
				</component>
			</div>

			<h1><slot name="title"></slot></h1>

			<div class="c-page-header__menu" v-if="showMenu">
				<slot name="menu"> </slot>
			</div>

			<slot name="footer"></slot>
		</div>
	</div>
</template>

<script>
export default {
	data: function () {
		return {};
	},

	watch: {},

	props: {
		byline: {
			type: String,
			default: 'Swipekit',
		},
		breadcrumbs: {
			type: Array,
			default: null,
		},
		title: {
			type: String,
			default: '',
		},
		showMenu: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		name: function () {
			let route = this.$route;

			return this.title || route.name || '?';
		},
		computedBreadcrumbs: function () {
			if (!this.breadcrumbs) {
				return;
			}

			let newBreadcrumbs = [];

			for (let i = 0; i < this.breadcrumbs.length; i++) {
				let crumb = this.breadcrumbs[i];
				let type = 'span';

				if (crumb.url) {
					type = 'router-link';
				}

				newBreadcrumbs.push({
					name: crumb.name,
					url: crumb.url || null,
					type: type,
				});

				if (i !== this.breadcrumbs.length - 1) {
					newBreadcrumbs.push({
						name: '/',
						url: null,
						type: 'span',
					});
				}
			}

			return newBreadcrumbs;
		},
	},

	methods: {
		toggleModal: function (type) {
			this.$store.dispatch('app/toggleModal', type);
		},
	},

	mounted: function () {},
};
</script>

<style lang="scss">
.c-page-header {
	width: 100%;
	padding-top: calc(var(--spacer) * 2);
	text-align: center;

	position: relative;

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 300px;
		color: var(--color-bg-5);

		> svg {
			width: 100%;
			height: 100%;
		}

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 250px;
			background: linear-gradient(0deg, var(--color-bg-2) 25%, hsla(var(--p), 10%, 98.5%, 0) 100%);
		}
	}

	.c-byline {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: var(--margin);
		text-transform: uppercase;
		font-family: var(--font-family-heading);
		font-weight: 600;
		font-size: var(--font-size-sm);
		background: linear-gradient(180deg, hsl(var(--p), 10%, 30%) 20%, hsl(var(--p), 40%, 40%));
		-webkit-background-clip: text;
		background-clip: text;
		-webkit-text-fill-color: transparent;

		> a {
			display: inline-block;
			margin-left: var(--margin);
			max-height: 34px;
			padding: var(--margin);
			border-radius: var(--border-radius);
			transition: background-color 120ms linear;

			&:hover,
			&:active {
				background-color: var(--color-bg-4);
			}
		}
	}

	&__breadcrumbs {
		display: flex;
		justify-content: center;
		margin-bottom: var(--margin-lg);

		a.c-byline {
			background: linear-gradient(180deg, hsl(var(--p), 45%, 55%) 20%, hsl(var(--p), 75%, 55%));
			-webkit-background-clip: text;
			background-clip: text;
			-webkit-text-fill-color: transparent;

			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
			}
		}

		.c-byline {
			margin: 0 var(--margin);
		}
	}

	&__inner {
		position: relative;
		z-index: 1;
		height: 100%;

		margin: 0 auto;
		padding-top: var(--margin-lg);
		padding-bottom: var(--margin);

		h1 {
			margin-bottom: 0;
			font-weight: 550;
			font-size: 36px;
			font-family: var(--font-family-body);
			line-height: 1.1;

			a {
				font-size: var(--font-size);
			}
		}

		.article-text {
			margin-top: var(--spacer-sm);
			margin-bottom: 0;
		}

		img {
			width: 32px;
			height: 32px;
			border-radius: var(--border-radius-full);
		}
	}

	&__user-blurb {
		display: flex;
		align-items: center;
		margin-bottom: var(--spacer-sm);

		span {
			margin-left: var(--margin-lg);
		}
	}

	&__menu {
		display: inline-flex;
		margin: 0 auto;
		margin-top: var(--margin-lg);
		padding: var(--margin);
		border-radius: 99px;

		box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 0px 0px 0.4px, rgba(0, 0, 0, 0.08) 0px 2px 4px -1px, rgba(0, 0, 0, 0.1) 0px 5px 38px -2px;

		> a {
			min-width: 140px;
			display: inline-block;
			//flex: 1 1 0px;
			padding: var(--margin-lg);
			color: var(--color-font) !important;
			border-radius: 12px;
			text-decoration: none !important;
			user-select: none;

			&:first-child {
				border-top-left-radius: 99px;
				border-bottom-left-radius: 99px;
			}
			&:last-child {
				border-top-right-radius: 99px;
				border-bottom-right-radius: 99px;
			}

			p {
				margin: 0;
				opacity: 0.75;
				font-size: var(--font-size-sm);
			}

			h6 {
				margin-bottom: 0;
				white-space: nowrap;
				line-height: 1;
			}

			&:hover,
			&:active {
				background-color: var(--color-bg-4);
			}

			&.active {
				background-color: hsl(var(--p), 70%, 63%);
				color: #fff !important;
			}

			&:not(:last-child) {
				margin-right: var(--margin);
			}
		}
	}

	@include media-breakpoint-down(sm) {
		padding-top: var(--spacer-lg);

		&__inner {
			padding-top: var(--spacer);
			h1 {
				font-size: 34px;
			}
		}
	}
}
</style>
