<template>
	<div class="c-board-empty-state">
		<article>
			<h4>This board is empty</h4>
			<p>Start saving ads to fill it up.</p>
		</article>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.c-board-empty-state {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: var(--spacer);
}
</style>
